// Here you can add other styles

.ag-watermark {
    display: none;
}

.c-header {
    background-color: #00ac9b;
    color: white;
}

.c-header-nav-link {
    color: white !important;
}